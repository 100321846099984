<template>
  <div class="addType commonF padding20 bgFFF paddingLR20 paddingT20">
    <h2 class="title">
      <div class="title_icon">
      </div>
      {{operation}}一级分类
    </h2>
    <div class="content">
      <el-form ref="form" class="commonF" :model="form" :rules="rule" label-width="150px">
        <el-form-item label="一级分类名称" prop="classify">
          <el-input v-model="form.classify" maxlength="50" style="width:230px"></el-input>
        </el-form-item>
        <el-form-item :label="$t('searchModule.remarks')" prop="content">
          <el-input type="textarea" v-model="form.content" maxlength="200" style="width:230px"></el-input>
        </el-form-item>
        <div style="text-align: center; margin-top: 20px;">
          <el-button type="primary" v-show="operation === '添加'" @click="save('form')">{{ $t('button.preservation') }}</el-button>
          <el-button type="primary" v-show="operation !== '添加'" @click="update('form')">{{ $t('button.preservation') }}</el-button>
          <el-button @click="$router.go(-1)">取消</el-button>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "addType",
  data() {
    return {
      typeCode: "",
      tenantList: [],
      tenantList2: [],
      operation: '', // 操作
      form: {
        classify: "",
        content: "",
        classifyId: ''
      },
      rule: {
        classify: [
          { required: true, message: "该项为必填项", trigger: "blur" }
        ]
      }
    };
  },
  methods: {
    init() {
      this.operation = this.$route.query.operation
      if (this.operation == '编辑') {
        this.typeCode = this.$route.query.typeCode;
        this.form = this.$route.query;
        this.$set(this.form, 'classify', this.$route.query.classifyOneName)
        this.$set(this.form, 'content', this.$route.query.remarks)
      }
    },
    save(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/customerClassify/add", {
              data: {
                ...this.form,
                type: 1
              }
            })
            .then(res => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            });
        }
      });
    },
    update(form) {
      this.$refs[form].validate(valid => {
        if (valid) {
          this.$axios
            .post("/acb/2.0/customerClassify/update", {
              data: {
                ...this.form,
                type: 1
              }
            })
            .then(res => {
              if (res.state == 0) {
                this.$alert("操作成功！", this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                }).then(() => {
                  this.$router.go(-1);
                });
              } else {
                this.$alert(res.desc, this.$t('pop_up.Tips'), {
                  confirmButtonText: this.$t('pop_up.Determine')
                });
              }
            });
        }
      });
    }
  },
  mounted() {
    this.init();
  }
};
</script>
